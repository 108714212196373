import React, { FunctionComponent } from "react"
import * as Styled from "./QuestionareHeaderSection.styled"
import LogoIcon from "../../../icons/LogoIcon"
import CursorContext from "../../CustomCursor/context/CursorContext"

interface Props {
  name: string
}

const QuestionareHeaderSection: FunctionComponent<Props> = ({ name }) => {
  const { events } = React.useContext(CursorContext)

  const navigateToHomepage = () => {
    if (location) {
      return (location.href = "/")
    }
  }

  return (
    <Styled.Section>
      <Styled.CircleBig />
      <Styled.CircleSmall />
      <Styled.Container>
        <Styled.LogoWrapper
          onClick={() => {
            navigateToHomepage()
          }}
          {...events}
        >
          <LogoIcon />
        </Styled.LogoWrapper>
        <Styled.MottoWrapper>
          <Styled.Line>
            <Styled.HeaderWrapper>Cześć, {name}</Styled.HeaderWrapper>
          </Styled.Line>
          <Styled.HeaderYellowWrapper>
            Poznajmy się bliżej
          </Styled.HeaderYellowWrapper>
          <Styled.Subheader>
            Odpowiedz na kilka pytań dotyczących&nbsp;Twojej firmy
          </Styled.Subheader>
          <Styled.ButtonContainer>
            <Styled.ButtonWrapper href="#ankieta">
              <Styled.HeaderButton>Wchodzę w to!</Styled.HeaderButton>
            </Styled.ButtonWrapper>
          </Styled.ButtonContainer>
        </Styled.MottoWrapper>
      </Styled.Container>
    </Styled.Section>
  )
}

export default QuestionareHeaderSection
