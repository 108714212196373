import React, { FunctionComponent, useRef } from "react"
import Circle from "../OfferSection/Circle"
import * as Styled from "./QuestionareSection.styled"
import { Header, Text, Underlined } from "../../Typography"
import { FormProvider, useForm } from "react-hook-form"
import Recaptcha from "../../Form/Recaptcha/Recaptcha"
import Button from "../../Button"
import axios from "axios"
import { ErrorMessage, SuccessMessage } from "../../Form/Form.styled"
import { StringParam, useQueryParam } from "use-query-params"
import { AnimatePresence } from "framer-motion"
import RecaptchaDisclaimer from "../../RecaptchaDisclaimer/RecaptchaDisclaimer"
import RadioGroup from "../../Form/RadioGroup/RadioGroup"
import { ButtonTexts } from "../FormSection/FormSection"
import TextInput from "../../Form/TextInput/TextInput"
import SwitchYesNo from "../../SwitchYesNo/SwitchYesNo"

type Props = {
  handleThanks: () => void
}

type FormData = {}

const QuestionareSection: FunctionComponent<Props> = ({ handleThanks }) => {
  const [leadKey] = useQueryParam("leadKey", StringParam)
  const [leadId] = useQueryParam("leadId", StringParam)
  const [reHash, setReHash] = React.useState<undefined | string | number>()
  const formRef = useRef<HTMLFormElement>(null)
  const [working, setWorking] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const methods = useForm<FormData>({})

  const onButtonClick = React.useCallback(() => {
    setReHash(Math.random())
    setWorking(true)
    setSuccess(false)
    setError(false)
  }, [])

  const preSubmitData = React.useCallback(
    value => {
      methods.handleSubmit(submitData, d => {
        setWorking(false)
      })()
    },
    [formRef]
  )

  const submitData = React.useCallback((data: FormData) => {
    const postData = {
      data: {
        ...data,
        leadKey,
      },
      issues: [leadId],
    }

    console.log(postData)

    axios
      .post(
        `https://automations.itenerum.com/leads/serioit/additionalQuestions`,
        postData
      )
      .then(response => {
        setWorking(false)
        setSuccess(true)
        handleThanks()
      })
      .catch(error => {
        setError(true)
        setWorking(false)
      })
  }, [])

  return (
    <Styled.Section id="ankieta">
      <Styled.Container>
        <Header as={"h2"}>
          Ankieta zajmie Ci tylko<br></br> <Underlined>kilka minut.</Underlined>
        </Header>
        <Styled.Content>
          <Text>
            Pozwoli nam stworzyć dla Ciebie ofertę, która najlepiej odpowie na
            wszystkie potrzeby.
          </Text>
          <Text>Serio, szybko pójdzie!</Text>
          <FormProvider {...methods}>
            <Styled.FormWrapper
              onSubmit={methods.handleSubmit(submitData)}
              ref={formRef}
            >
              <Styled.FormColumn>
                <SwitchYesNo
                  name={"isBranch"}
                  label="Czy Twoja firma jest oddziałem lub filią innej organizacji?"
                />
                <div style={{ height: 50 }} />
                <SwitchYesNo
                  name={"isOutsourcing"}
                  label="Czy Twoja firma korzysta aktualnie z outsourcingu IT?"
                />
                <div style={{ height: 50 }} />
                <SwitchYesNo
                  name={"contact"}
                  label="Czy chciałbyś, aby w razie potrzeby Twoi pracownicy mogli samodzielnie
                kontaktować się z naszym Centrum Pomocy?"
                />
                <div style={{ height: 50 }} />

                <TextInput
                  required={"Wpisz branżę"}
                  name={"industry"}
                  label={"W jakiej branży działa Twoja firma?"}
                  placeholder={"Wpisz branżę"}
                  pattern={{
                    message: "Wpisz poprawną branżę",
                    value: /[a-zA-z]+/,
                  }}
                />
                <RadioGroup
                  title={"Jak istotne jest dla Ciebie wsparcie w weekendy?"}
                  name={"weekendSupport"}
                  values={["Nieistotne", "Mile widziane", "Konieczne"]}
                />

                <RadioGroup
                  title={
                    "Jak istotne jest dla Ciebie wsparcie w godzinach nocnych?"
                  }
                  name={"nightSupport"}
                  values={["Nieistotne", "Mile widziane", "Konieczne"]}
                />

                <RadioGroup
                  title={"Jaki jest model pracy Twojej organizacji?"}
                  name={"workModel"}
                  values={[
                    "Głównie" + " w biurze",
                    "Hybrydowy",
                    "Głównie zdalnie",
                  ]}
                />

                <RadioGroup
                  title={
                    "Jak istotne dla Ciebie jest stworzenie dokumentacji i procedur umożliwiających" +
                    " uzyskanie certyfikacji, takiej jak np. ISO/IEC 27001?"
                  }
                  name={"procedures"}
                  values={["Nieistotne", "Mile widziane", "Bardzo ważne"]}
                />

                <RadioGroup
                  title={
                    "Który model współpracy bardziej odpowiada Twoim oczekiwaniom?"
                  }
                  name={"partnershipModel"}
                  values={[
                    "Indywidualny opiekun",
                    "Wieloosobowy zespół wsparcia",
                  ]}
                />

                <RadioGroup
                  title={
                    "Jak widzisz nasz udział w kreowaniu przyszłych rozwiązań informatycznych?"
                  }
                  name={"futureSolutions"}
                  values={[
                    "Mam własną wizję i potrzebuję wykonawcy",
                    "Wspólnie" + " wypracowujemy rozwiązania",
                    "Podaję cele, a wy znajdujecie rozwiązania",
                  ]}
                />

                <RadioGroup
                  title={"Jak oceniasz obecny poziom organizacji swojego IT?"}
                  name={"clientsIt"}
                  values={[
                    "Totalny chaos",
                    "Wymaga korekt",
                    "Jest dobrze",
                    "Nie umiem ocenić",
                  ]}
                />

                <RadioGroup
                  title={
                    "Czy zależy Ci na tym, żebyśmy realizowali zamówienia na sprzęt i oprogramowanie?"
                  }
                  name={"technicalOrders"}
                  values={[
                    "Tak, zależy mi na tym",
                    "Tak, ale mogę to realizować samodzielnie",
                    "Nie, mam już dostawców i nie chcę ich zmieniać",
                  ]}
                />

                <RadioGroup
                  title={
                    "Jak duża jest rotacja w Twojej organizacji w ujęciu rocznym?"
                  }
                  name={"yearRotation"}
                  values={[
                    "Duża - więcej niż 1/4 zespołu",
                    "Mała - mniej niż 1/4 zespołu",
                  ]}
                />

                <RadioGroup
                  title={
                    "Czy przechowujesz dane, których wyciek mógłby spowodować poważne konsekwencje" +
                    " dla Twojego przedsiębiorstwa?"
                  }
                  name={"sensitiveData"}
                  values={[
                    "Tak, to jedno z największych zagrożeń",
                    "Tak",
                    "Nie",
                    "Nie" + " umiem ocenić",
                  ]}
                />
                <Recaptcha executionHash={reHash} onSuccess={preSubmitData} />
                <Button
                  type="button"
                  onClick={onButtonClick}
                  disabled={working}
                  text={
                    working
                      ? ButtonTexts.working
                      : success
                      ? ButtonTexts.success
                      : ButtonTexts.default
                  }
                ></Button>
                <RecaptchaDisclaimer />
                <Styled.MessageWrapper>
                  <AnimatePresence>
                    {success ? (
                      <SuccessMessage
                        animate={{ y: 0, opacity: 1 }}
                        initial={{ y: -100, opacity: 0 }}
                      >
                        Formularz został poprawnie przesłany. <br /> Na maila
                        wyślemy potwierdzenie zgłoszenia.
                      </SuccessMessage>
                    ) : (
                      <SuccessMessage
                        style={{ opacity: 0 }}
                        initial={{ y: 0, opacity: 0 }}
                        animate={{ y: 100, opacity: 0 }}
                        exit={{ y: 100, opacity: 0 }}
                      >
                        Formularz został poprawnie przesłany. <br /> Na maila
                        wyślemy potwierdzenie zgłoszenia.
                      </SuccessMessage>
                    )}
                    {error ? (
                      <ErrorMessage
                        animate={{ y: 0, opacity: 1 }}
                        initial={{ y: -100, opacity: 0 }}
                        style={{ marginTop: "0rem" }}
                      >
                        Wystąpił problem komunikacji z serwerem
                      </ErrorMessage>
                    ) : (
                      <ErrorMessage
                        style={{ opacity: 0 }}
                        initial={{ y: 0, opacity: 0 }}
                        animate={{ y: 100, opacity: 0 }}
                        exit={{ y: 100, opacity: 0 }}
                      >
                        Wystąpił problem komunikacji z serwerem
                      </ErrorMessage>
                    )}
                  </AnimatePresence>
                </Styled.MessageWrapper>
              </Styled.FormColumn>
            </Styled.FormWrapper>
          </FormProvider>
        </Styled.Content>
      </Styled.Container>
      <Styled.CircleConatiner>
        <Circle />
      </Styled.CircleConatiner>
    </Styled.Section>
  )
}

export default QuestionareSection
