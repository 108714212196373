import styled, { css } from "styled-components"
import ThemeStyle from "../../../styles/ThemeStyle"
import { mediaUp, Transition } from "../../../styles/Mixins"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: none;
    height: 0;
    width: 0;

    &:active + div {
      transform: scale(0.9);
    }
  }
`

type CheckboxProps = {
  checked: boolean
}
export const QuestionareHeader = styled.div`
  line-height: 2.8rem;
  margin: 4rem 0rem;

  display: block;
  width: 100%;
  padding-bottom: 0.8rem;
  font-size: 2.2rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      cursor: none !important;
    `
  )}
`

export const Radio = styled.div<CheckboxProps>`
  width: 2.4rem;
  height: 2.4rem;
  border: 3px solid ${ThemeStyle.colors.main};
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: none;
`
export const Inner = styled.div<CheckboxProps>`
  width: 0;
  height: 0;
  background-color: #363636;
  opacity: 75%;
  border-radius: 50%;

  cursor: none;
  ${Transition("all", 0.1)}
  ${props =>
    props.checked &&
    css`
      width: 100%;
      height: 100%;
    `}
`

export const LabelText = styled.div`
  font-size: 2rem;
  padding-left: 1rem;
  font-weight: 300;
  cursor: none;
  display: flex;
  align-items: center;
  line-height: 2.65rem;
`

export const Label = styled.label`
  position: relative;
  display: flex;
  padding-bottom: 0.9rem;
  cursor: none;
`
