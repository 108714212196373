import styled, { css, keyframes } from "styled-components"
import {
  AppContainer,
  AppSection,
  DesktopHidden,
  mediaUp,
  MobileHidden,
} from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"

export const Section = styled.section`
  ${AppSection};
  position: relative;
`

export const Container = styled.div`
  ${AppContainer};
  padding-top: 10rem;
  padding-bottom: 10rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 22rem;
      padding-bottom: 22rem;
    `
  )}
`

export const Content = styled.div`
  margin-top: 5rem;
  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      width: 50%;
      margin-left: auto;
      margin-right: 0;
    `
  )}
`

export const QuestionareHeader = styled.div`
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: 300;
  color: ${ThemeStyle.colors.questionText};
  margin: 4rem 0rem;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const CircleConatiner = styled.div`
  /* ${MobileHidden}; */
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.lg,
    css`
      display: block;
      width: 55rem;
      height: 55rem;
      position: absolute;
      top: 50rem;
      left: 0;
      transform: translate(-30%, -10%);
      svg {
        animation: ${rotate} 24s linear infinite;
        width: 100%;
        height: 100%;
      }
    `
  )}
`

export const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 5rem;
    `
  )}
`
export const FormColumn = styled.div`
  .grecaptcha-badge {
    visibility: hidden;
  }
`

export const MobileSpacer = styled.div`
  ${DesktopHidden};
  width: 100%;
  height: 4rem;
`
export const MessageWrapper = styled.div`
  margin-top: 6rem;
  font-size: 1.8rem;
`
