import React, { useEffect, useState } from "react"
import MainLayout from "../layouts/MainLayout/MainLayout"
import QuestionareHeaderSection from "../components/Sections/QuestionareHeaderSection/QuestionareHeaderSection"
import QuestionareSection from "../components/Sections/QuestionareSection/QuestionareSection"
import QuestionareThanksSection from "../components/Sections/QuestionareThanksSection/QuestionareThanksSection"
import { StringParam, useQueryParam } from "use-query-params"
import useScrollTo from "../hooks/useScrollTo"

const Questionare = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [name] = useQueryParam("name", StringParam)
  const handleSubmit = () => {
    setIsSubmitted(true)
  }
  const scrollToForm = useScrollTo("thanks")
  useEffect(() => {
    isSubmitted ? scrollToForm() : () => {}
  }, [isSubmitted])
  return (
    <MainLayout navigation={false}>
      <QuestionareHeaderSection name={name ?? ""} />
      {isSubmitted ? (
        <QuestionareThanksSection id="thanks" />
      ) : (
        <QuestionareSection handleThanks={handleSubmit} />
      )}
    </MainLayout>
  )
}

export default Questionare
