import styled, { css } from "styled-components"
import {
  AppContainer,
  mediaUp,
  AppSection,
  Transition,
} from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { Header as BaseHeader, Text } from "../../Typography"

export const Section = styled.header`
  ${AppSection};

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: 100%;
      height: 100vh;
      background: ${ThemeStyle.colors.white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    `
  )}
`

export const Container = styled.div`
  ${AppContainer};
  padding: 12rem 0rem;
  width: 100%;
  margin: 0 auto;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 12rem 0rem 0rem;
      height: auto;
      height: 100%;
    `
  )}
`
export const HeaderWrapper = styled.div`
  text-align: center;
  width: 100%;
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: 80rem;
    `
  )}

  h2 {
    font-size: clamp(3.4rem, 2.8rem + 3.0000000000000004vw, 6.4rem);
  }
`
