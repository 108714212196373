import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import ThemeStyle from "../../styles/ThemeStyle"
import { Transition } from "../../styles/Mixins"

type SwitchYesNoProps = {
  checked: boolean
}

export const Wrapper = styled.div`
  input {
    position: absolute;
    opacity: 0;
    cursor: none;
    height: 0;
    width: 0;
  }
`

export const Container = styled.div`
  height: 5.4rem;
  width: 17.4rem;
  display: flex;
  position: absolute;
  justify-content: space-between;
  z-index: 12;
`

export const SwitchText = styled.div`
  display: flex;
  height: 100%;
  width: 8.7rem;
  justify-content: center;
  align-items: center;

  &.no {
    color: #c4c4c4;
  }

  &.yes {
    color: ${ThemeStyle.backgrounds.dark};
  }
`

export const Background = styled(motion.div)`
  margin-top: 1.5rem;
  width: 17.4rem;
  height: 5.4rem;
  display: flex;
  background-color: ${ThemeStyle.backgrounds.light};
  border-radius: 3rem;

  &.no {
    justify-content: flex-start;
  }
  &.yes {
    justify-content: flex-end;
  }
`

export const Dot = styled(motion.div)`
  z-index: 10;
  border-radius: 3rem;
  width: 9.4rem;
  height: 5.4rem;
  background-color: ${ThemeStyle.colors.main};
`
