import React from "react"
import { StyledBasicPseudo } from "../../../styles/BasicStyles"
import * as Styled from "./QuestionareThanksSection.styled"
import { Header, Underlined } from "../../Typography"

const QuestionareThanksSection = () => {
  return (
    <Styled.Section id={"thanks"}>
      <Styled.Container>
        <Styled.HeaderWrapper>
          <Header as={"h2"}>Dzięki, to już wszystko!</Header>
          <Header as={"h2"}>
            Wrócimy do Ciebie <Underlined>ze szczegółową ofertą.</Underlined>
          </Header>
        </Styled.HeaderWrapper>
      </Styled.Container>
    </Styled.Section>
  )
}

export default QuestionareThanksSection
