import React, { FunctionComponent } from "react"
import * as Styled from "./SwitchYesNo.styled"
import { Cursor } from "../CustomCursor/CustomCursor.styled"
import CursorContext from "../CustomCursor/context/CursorContext"
import * as Form from "../Form/Form.styled"
import { useFormContext, useWatch } from "react-hook-form"

type Props = {
  defaultChecked?: boolean // zmienmy na defaultChecked - done
  onChange?: (value: boolean) => void
  name: string
  required?: boolean | string
  label: string
}

const SwitchYesNo: FunctionComponent<Props> = ({
  defaultChecked = false,
  onChange,
  ...props
}) => {
  const { register } = useFormContext()
  const checked = useWatch({ name: props.name })
  const { events } = React.useContext(CursorContext)

  return (
    <Styled.Wrapper {...events}>
      <input
        id={props.name}
        type="checkbox"
        {...register(props.name, { required: props.required })}
      />
      <Form.Label htmlFor={props.name}>
        {props.label}
        <Styled.Background className={!checked ? "yes" : "no"}>
          <Styled.Container>
            <Styled.SwitchText className={checked ? "yes" : "no"}>
              tak
            </Styled.SwitchText>
            <Styled.SwitchText className={checked ? "no" : "yes"}>
              nie
            </Styled.SwitchText>
          </Styled.Container>
          <Styled.Dot
            layout
            transition={spring}
            className={checked ? "yes" : "no"}
          />
        </Styled.Background>
      </Form.Label>
    </Styled.Wrapper>
  )
}

const spring = {
  type: "spring",
  stiffness: 900,
  damping: 45,
}

export default SwitchYesNo
