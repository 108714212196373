import React, { CSSProperties, FunctionComponent } from "react"
import * as Styled from "./Radio.styled"
import * as Form from "../Form.styled"
import { useFormContext, useWatch } from "react-hook-form"
import useError from "../useError"
import CursorContext from "../../CustomCursor/context/CursorContext"

type Props = {
  name: string
  style?: CSSProperties
  required?: boolean | string
  values: string[]
  title: string
}

const RadioGroup: FunctionComponent<Props> = ({ children, ...props }) => {
  const { events } = React.useContext(CursorContext)
  const { register } = useFormContext()
  const error = useError(props.name)
  const checked = useWatch({ name: props.name })
  return (
    <>
      <Styled.QuestionareHeader>{props.title}</Styled.QuestionareHeader>
      <Form.Wrapper style={props.style}>
        <Styled.Container {...events}>
          {props.values.map((value, idx) => (
            <React.Fragment key={idx}>
              <Styled.Label>
                <input
                  id={props.name}
                  {...register(props.name, {
                    required: "Zaznacz to pole",
                  })}
                  type="radio"
                  value={value}
                />
                <Styled.Radio checked={checked === value}>
                  <Styled.Inner checked={checked === value} />
                </Styled.Radio>
                <Styled.LabelText>{value}</Styled.LabelText>
              </Styled.Label>
            </React.Fragment>
          ))}
          <Form.ErrorMessage>{error}</Form.ErrorMessage>
        </Styled.Container>
      </Form.Wrapper>
    </>
  )
}

export default RadioGroup
