import styled, { css } from "styled-components"
import {
  AppContainer,
  mediaUp,
  AppSection,
  Transition,
} from "../../../styles/Mixins"
import ThemeStyle from "../../../styles/ThemeStyle"
import { Header as BaseHeader, Text } from "../../Typography"
import Button from "../../Button/Button"
import { motion } from "framer-motion"

export const Section = styled.header`
  ${AppSection};
  background-color: ${ThemeStyle.backgrounds.dark};
  height: auto;
  padding-top: 12rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 0rem;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      ${Transition("all", 1)};
      overflow: hidden;
    `
  )};
`

export const Container = styled.div`
  ${AppContainer};
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 12rem 0rem 0rem;
      position: relative;
    `
  )};
`

export const LogoWrapper = styled.div`
  ${AppContainer};
  position: absolute;
  top: 3.5rem;
  left: 0;
`

export const MottoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 8rem;
  padding: 0rem 1rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      margin-bottom: 0rem;
      align-items: center;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      margin-bottom: 0rem;
      padding: 0rem;
    `
  )};
`

export const Line = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      width: auto;
      flex-direction: row;
    `
  )}
`

export const HeaderWrapper = styled.div`
  font-size: 4.2rem;
  line-height: 4.8rem;
  color: ${ThemeStyle.colors.headerInverted};
  text-align: left;
  padding-bottom: 0rem;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5.2rem;
      line-height: 1rem;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 3rem;
      height: auto;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 7.2rem;
      line-height: 1rem;
      text-align: center;
      padding-bottom: 3rem;
    `
  )};
`
export const HeaderYellowWrapper = styled.div`
  padding-bottom: 0.8em;
  font-size: 4.2rem;
  line-height: 4.8rem;
  color: ${ThemeStyle.colors.main};
  text-align: left;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 5.2rem;
      line-height: 5.2rem;
      text-align: center;
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      font-size: 7.2rem;
      line-height: 7.2rem;
      text-align: center;
    `
  )};
`

export const Subheader = styled(Text)`
  color: ${ThemeStyle.colors.textInverted};
  text-align: left;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      text-align: center;
      width: 45rem;
    `
  )};
`

export const ButtonContainer = styled.div`
  margin: auto;
`
export const ButtonWrapper = styled.a``

export const HeaderButton = styled(Button)`
  background-color: ${ThemeStyle.colors.black};
  color: ${ThemeStyle.colors.white};
  font-size: 1.6rem;
  border: 1px solid ${ThemeStyle.colors.white};
  padding: 3rem 7rem;
  margin-top: 20rem;

  &:hover {
    background-color: ${ThemeStyle.colors.main};
    color: ${ThemeStyle.colors.black};
    border: 1px solid ${ThemeStyle.colors.main};
  }
  &:active {
    background-color: ${ThemeStyle.colors.main};
    color: ${ThemeStyle.colors.black};
    border: 1px solid ${ThemeStyle.colors.main};
  }
`

export const CircleBig = styled(motion.div)`
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: block;
      position: absolute;
      top: -150px;

      width: 650px;
      height: 650px;
      left: -370px;

      background: rgb(254, 253, 83);
      background: radial-gradient(
        circle,
        rgba(254, 253, 83, 1) 0%,
        rgba(254, 253, 83, 1) 25%,
        rgba(254, 253, 83, 0) 50%
      );

      filter: blur(97px);
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      width: 950px;
      height: 950px;
      left: -570px;
      top: -200px;
    `
  )};
`
export const CircleSmall = styled(motion.div)`
  display: none;

  ${mediaUp(
    ThemeStyle.breakpoints.sm,
    css`
      display: block;
      position: absolute;
      bottom: 100px;
      right: 150px;
      width: 80px;
      height: 80px;

      background: rgb(254, 253, 83);
      background: radial-gradient(
        circle,
        rgba(254, 253, 83, 1) 0%,
        rgba(254, 253, 83, 1) 75%,
        rgba(254, 253, 83, 0) 50%
      );

      filter: blur(20px);
    `
  )};

  ${mediaUp(
    ThemeStyle.breakpoints.xxl,
    css`
      right: 275px;
      width: 120px;
      height: 120px;
    `
  )};
`
